import { GetterTree } from 'vuex';
import { NumericCodeValue, RootState } from '@/store/types';
import { HlaAntibodyTag, HlaTypingTag, VirologyType } from '@/store/labs/types';
import { filterInternalOptions, uniqueElements } from '@/utils';
import { JourneyCancellationReasons,LookupsState, Organ, OrganDiseaseCode, OrganSpecification, OfferType,
  HlaDictionaryEntry, HlaMolecularCode, HlaSerologicalCode, HlaMolecularRelation, HlaSerologicalRelation,
  HlaDictionaryEpitopeResult, HlaEpitope, EPITOPE_PRESENT, EPITOPE_NOT_PRESENT, EPITOPE_OPTIONS, Gender,
  SEXES_IN_GENDER_TABLE, HEART_STATUSES_EXCLUDED_FROM_SECONDARY, OrganWaitlistMedicalStatus,
  OrganWaitlistMedicalStatusValue, HlaAntibodyTestKit, BloodTypeValue, SubBloodType, SubBloodTypeValue,
  OrganCodeValue, AttachmentCategory, OfferReasonCategory, BloodType, VirologyCode, VirologyResultCodesValue,
  CodePrecedence, VIROLOGY_RESULT_CODE_PRECEDENCE, InsurancePlanCode,
  discontinueAllocationLookupType, discontinueAllocationEntries, discontinueAllocationReasonsSubTables, discontinueAllocationReason } from '@/store/lookups/types';
import { AllocationOfferTypeValues } from "@/store/allocations/types";
import { NotifiableEventChannel } from "@/store/users/types";
import { GenericCodeValue } from '@/store/types';
import { NotificationCodeOrdering, LookupEntry, InsuranceType } from '@/store/lookups/types';
import i18n from '@/i18n';

// Helper method to sort lookup options based on specified order of precedence
// e.g. Virology Result options are displayed in a different order than found in the lookup data
function sortByPrecedence(options: GenericCodeValue[], precedenceList: CodePrecedence[]): GenericCodeValue[] {
  const sorted = options.sort((a: GenericCodeValue, b: GenericCodeValue) => {
    const aPrecedence = precedenceList.find((precedence: CodePrecedence) => {
      return precedence.code === a.code;
    });
    const bPredence = precedenceList.find((precedence: CodePrecedence) => {
      return precedence.code === b.code;
    });
    const aOrder = aPrecedence?.order || 0;
    const bOrder = bPredence?.order || 0;
    return aOrder - bOrder;
  });
  return sorted;
}

export const getters: GetterTree<LookupsState, RootState> = {

  /**
   * Returns alphabetically sorted list of entries
   *
   * @param entries lookup entries
   * @returns {any[]} array of lookup entries sorted alphabetically
   */
  getSorted(state) {
    return (entries: any[]): any[] => {
      if (entries.length > 0) {
        return entries.sort(function(a, b) {
          const aa = a.value.toUpperCase();
          const bb = b.value.toUpperCase();
          if (aa < bb) {
            return -1;
          }
          if (aa > bb) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        return entries;
      }
    };
  },

  /**
   * Filters out entries with an expired date before the cutoff date (e.g. today)
   *
   * @param entries lookup entries
   * @param expired whether to filter expired lookups
   * @param recursive whether or not to perform recursion
   * @returns {any[]} array of lookup entries without expired entries
   */
  filterExpired(state, getters) {
    return (entries: any[], recursive?: boolean, label?: string): any[] => {
      if (entries == null) { return []; } // check for null/undefined entries
      if (!Array.isArray(entries)) { return []; } // check for non-arrays
      // Filter out expired entries
      const filteredEntries = entries.filter((entry: any) => {
        const expiredDate: string | undefined = entry.expired_date;
        if (expiredDate === undefined) {
          // No expired date? keep it
          return true;
        }
        const expiryTimestamp = Date.parse(expiredDate);
        if (isNaN(expiryTimestamp)) {
          // Invalid expired date? keep it
          return true;
        }
        // Valid expired date? keep only if expiry is after cutoff
        const cutOffExpiry = new Date().getTime(); // Default cutoff date is today
        return expiryTimestamp > cutOffExpiry;
      });
      if (recursive) {
        // Filter out expired subtable entries
        const recursiveFilteredEntries = filteredEntries.map((entry: any) => {
          const subtablesObject = entry.sub_tables;
          if (subtablesObject === undefined) {
            return entry;
          }
          const entryWithFilteredSubtables = Object.assign({}, entry);
          const subtableNames = Object.keys(subtablesObject);
          subtableNames.forEach((subtableName: string) => {
            const subtableEntries = entryWithFilteredSubtables.sub_tables[subtableName];
            const filteredSubtableEntries = getters.filterExpired(subtableEntries, true, subtableName);
            entryWithFilteredSubtables.sub_tables[subtableName] = filteredSubtableEntries;
          });
          return entryWithFilteredSubtables;
        });
        return recursiveFilteredEntries;
      } else {
        return filteredEntries;
      }
    };
  },

  defaultLookup(state, getter) {
    // Return function that takes lookup_id as input and returns the default entry's code if it exists
    return (lookupId: string): any => {
      // Fetch lookup table based on lookup_id
      const lookupsTable = state[lookupId];
      if (lookupsTable === undefined) {
        return undefined;
      }
      // Find first item in lookup table with default: true
      const defaultEntry = lookupsTable.find((entry: any) => {
        return entry.default;
      });
      // Return code parameter of the default entry
      return defaultEntry ? defaultEntry.code : undefined;
    };
  },

  /**
   * Return function that takes lookup_id as input and returns true if lookup table exists
   *
   * @param lookupId lookup id  
   * @returns {boolean} true or false based on the lookup existing and containing data
   */
   lookupCacheExists(state, getter) {
    return (lookupId: string): boolean => {
      // Fetch lookup table based on lookup_id
      const lookupTable = state[lookupId];

      // check for undefined
      if (lookupTable === undefined) return false;

      // return true if lookupTable exists & contains data
      return lookupTable && lookupTable.length > 0;
    };
  },

  lookupValue(state) {
    // Returns a function that takes a lookup code and id, then return the value of the lookup
    return (code: string | undefined, lookupId: string): any => {
      const lookupsTable = state[lookupId];
      if (lookupsTable === undefined || code === undefined) {
        return undefined;
      }
      const lookupValue = lookupsTable.find((item: any) => {
        return item.code == code ? item.value : undefined;
      });
      return lookupValue ? lookupValue.value : undefined;
    };
  },

  lookupValueWithLanguage(state) {
    // Returns a function that takes a lookup code and id, then return the value of the lookup
    return (code: string | undefined, lookupId: string): any => {
      // load lookup from state store
      const lookupsTable = state[lookupId];
      // check for content
      if (lookupsTable === undefined || code === undefined) { return code; }
      // find lookup value
      const lookupValue = lookupsTable.find((item: LookupEntry) => { return item.code.toString() == code.toString(); });
      // return value ('value' already translated when lookup first loaded)
      return lookupValue && lookupValue.value ? lookupValue.value : undefined;
    };
  },

  lookupValueNumeric(state) {
    return (code: number, lookupId: string): string|null => {
      const lookupsTable = state[lookupId];
      if (!lookupsTable) {
        return null;
      }
      const lookupValue = lookupsTable.find((item: any) => {
        return item.code === code;
      });
      if (!lookupValue) {
        return null;
      }
      return lookupValue.value || null;
    };
  },
  getDiscontinueAllocationReasons(state) {
    return (type: discontinueAllocationLookupType): discontinueAllocationReason[]|undefined => {
      const entries: discontinueAllocationEntries[]|undefined = state && state.discontinue_allocation_categories_reasons ? state.discontinue_allocation_categories_reasons : undefined; // ? state?.discontinue_allocation_categories_reasons?.entries : null;
      if (!entries) { return undefined; }

      const entry: discontinueAllocationEntries|undefined = entries.find((entry: any) => {
        return entry.code === type;
      });

      return entry && entry.sub_tables ? entry.sub_tables.discontinue_allocation_reasons : undefined;
    };
  },
  ethnicity(state) {
    return state.ethnicity || [];
  },
  organ(state) {
    return state.organ;
  },
  /**
   * Returns organ specification lookup entries by deceased_donor
   *
   * @param organCode organ code
   * @returns {OrganSpecification[]} array of organ specification lookup entries
   */
  deceasedDonorsSpecificationsByOrganCode(state, getters) {
    return (organCode: string): OrganSpecification[] => {
      if (!state.organ || !organCode) return [];

      // Retrieve information based on organCode
      const organLookupEntry = state.organ.find((organ: Organ) => {
        return organ.code.toString() === organCode.toString();
      });

      // Return if nothing is in our sub_table
      if (!organLookupEntry || !organLookupEntry.sub_tables) return [];

      // The unfiltered list of options by deceased donor
      const organSpecifications: OrganSpecification[] = organLookupEntry.sub_tables.organ_specifications || [];
      const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        // filter by organ code & deceased_donor == true
        return !!organSpec.offer && organSpec.deceased_donor == true;
      });

      // return options
      return offerOrganSpec;
    };
  },
  /**
   * Returns organ specification lookup entries by living_donor
   *
   * @param organCode organ code
   * @returns {OrganSpecification[]} array of organ specification lookup entries
   */
   livingDonorsSpecificationsByOrganCode(state, getters) {
    return (organCode: string): OrganSpecification[] => {
      if (!state.organ || !organCode) return [];

      // Retrieve information based on organCode
      const organLookupEntry = state.organ.find((organ: Organ) => {
        return organ.code.toString() === organCode.toString();
      });

      // Return if nothing is in our sub_table
      if (!organLookupEntry || !organLookupEntry.sub_tables) return [];

      // The unfiltered list of options by deceased donor
      const organSpecifications: OrganSpecification[] = organLookupEntry.sub_tables.organ_specifications || [];
      const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        // filter by organ code & living_donor == true
        return !!organSpec.offer && organSpec.living_donor == true;
      });

      // return options
      return offerOrganSpec;
    };
  },
  cause_of_death_category(state) {
    return state.cause_of_death_category;
  },
  virology_codes_combined(state) {
    return (type: number): VirologyCode[] => {
      const virologyCodes: VirologyCode[] = state.virology_codes_combined || [];
      let results: VirologyCode[] = [];
      switch(type) {
        case VirologyType.DeceasedDonor:
          results = virologyCodes.filter((item: VirologyCode) => { return item.donor; });
          results.sort((a: any, b: any) => { return a.donor_order - b.donor_order; });
          break;
        case VirologyType.LivingDonor:
          results = virologyCodes.filter((item: VirologyCode) => { return item.living_donor; });
          results.sort((a: any, b: any) => { return a.donor_order - b.donor_order; });
          break;
        default:
          results = virologyCodes.filter((item: VirologyCode) => { return item.recipient; });
          break;
      }
      return results;
    };
  },
  virology_codes_secondary(state) {
    return (enableDonor: boolean): any => {
      const virology_codes = state.virology_codes_secondary || [];
      return virology_codes.filter((code: any) => enableDonor ? code.donor === true : code.recipient === true);
    };
  },
  country(state) {
    return state.country;
  },
  province(state) {
    return state.province;
  },
  region(state) {
    return state.region;
  },
  us_state(state) {
    return state.us_state;
  },
  recipient_referral_status_type(state) {
    return state.recipient_referral_status_type;
  },
  dialysisType(state) {
    return state.dialysis_type;
  },
  dialysis_access_mode(state) {
    return state.dialysis_access_mode;
  },
  donor_organ_transplant_program(state) {
    return state.donor_organ_transplant_program;
  },
  donor_referer_profession(state) {
    return state.donor_referer_profession;
  },
  blood_type(state) {
    return state.blood_type;
  },
  dialysis_location_type(state) {
    return state.dialysis_location_type;
  },
  dialysis_providers(state) {
    return state.dialysis_providers;
  },
  hla_testing_method(state) {
    return state.hla_testing_method;
  },
  inotrope_types(state) {
    return state.inotrope_types;
  },
  recipientAssessmentDecisions(state) {
    return state.recipient_assessment_decisions;
  },
  recipientConsultationDecisions(state) {
    return state.recipient_consultation_decisions;
  },
  recipientReferralDecision(state) {
    return state.recipient_referral_decision;
  },
  rhIndicator() {
    return [
      { code: '+', value: '+' },
      { code: '-', value: '-' },
      { code: '?', value: '?' }
    ];
  },
  insurance() {
    return [
      { code: 'OHIP', value: 'OHIP' },
      { code: 'WSIB', value: 'WSIB' },
      { code: 'Other', value: 'Other' }
    ];
  },
  recipientType() {
    return [
      { code: 'Recipient', value: 'Recipient' },
      { code: 'Living Donor', value: 'Living Donor' }
    ];
  },
  allAssessmentOutcomes() {
    return [
      { code: 'Recipient Declines', value: 'Recipient Declines' },
      { code: 'Recipient Not Suitable', value: 'Recipient Not Suitable' },
      { code: 'Further Treatment Required', value: 'Further Treatment Required' },
      { code: 'Further Testing Required', value: 'Further Testing Required' },
      { code: 'Recipient Suitable', value: 'Recipient Suitable' }
    ];
  },
  allRecipientCoordinators() {
    return [
      { code: 'Madonna Wehner', value: 'Madonna Wehner' },
      { code: 'Mackenzie Graham', value: 'Mackenzie Graham' },
      { code: 'Lucy Pfeffer', value: 'Lucy Pfeffer' },
      { code: 'Betty Schmidt', value: 'Betty Schmidt' },
      { code: 'Gwen Schaden', value: 'Gwen Schaden' },
      { code: 'Naomi Mosciski', value: 'Naomi Mosciski' },
      { code: 'Iris Cole', value: 'Iris Cole' },
      { code: 'Danielle Wiegand', value: 'Danielle Wiegand' }
    ];
  },
  allDonorTypes() {
    return [
      { code: 'Living Donor Only', value: 'Living Donor Only', deceased_donor: false, living_donor: true },
      { code: 'Deceased Donor Only', value: 'Deceased Donor Only', deceased_donor: true, living_donor: false },
      { code: 'Deceased or Living Donor', value: 'Deceased or Living Donor', deceased_donor: true, living_donor: true }
    ];
  },
  allHospitals() {
    return [
      { code: 'London Health Sciences Centre', value: 'London Health Sciences Centre' },
      { code: 'St.Josephs Healthcare Hamilton', value: 'St.Josephs Healthcare Hamilton' },
      { code: 'Sick Kids Hospital', value: 'Sick Kids Hospital' },
      { code: 'University Health Network', value: 'University Health Network' },
      { code: 'St.Michaels Hospital', value: 'St.Michaels Hospital' },
      { code: 'The Ottawa Hospital', value: 'The Ottawa Hospital' },
      { code: 'Ottawa Heart Institute', value: 'Ottawa Heart Institute' },
      { code: 'Kingston General Hospital', value: 'Kingston General Hospital' }
    ];
  },
  allDispositionStatus() {
    return [
      { code: 'Incomplete', value: 'Incomplete' },
      { code: 'Deferred', value: 'Deferred' },
      { code: 'Declined', value: 'Declined' },
      { code: 'Cancelled', value: 'Cancelled' },
      { code: 'Accepted', value: 'Accepted' }
    ];
  },
  organName(state: LookupsState,getters, rootState, rootGetters) {
    // Return function that takes numeric organ code as input and returns the organ name as text
    return (organCode?: number): string | undefined => {
      if (!state.organ) {
        return undefined;
      }
      // Find first item in lookup table with the specified organ code
      const organLookup = state.organ.find((organLookup: Organ) => {
        return organLookup.code == organCode;
      });
      if (!organLookup) {
        return undefined;
      }
      return organLookup.value;
    };
  },

  // Get array of options based on both expired and non-expired entries in organ lookup 
  organOptions(state: LookupsState) {
    return (type?: string): NumericCodeValue[] => {
      const organLookups = state.organ;
      if (!organLookups || organLookups.length === 0) {
        return [];
      }
      // Filter by type if one is specified
      let filtered: Organ[];
      if (type !== undefined) {
        filtered = organLookups.filter((organLookup: Organ) => {
          return organLookup.type == type;
        });
      } else {
        filtered = organLookups;
      }
      const options = filtered.map((organLookup: Organ) => {
        return {
          code: organLookup.code,
          value: organLookup.value,
          expired_date: organLookup.expired_date,
        };
      });
      return options;
    };
  },

  // Get array of options based only on the non-expired entries in organ lookup
  nonExpiredOrganOptions(state: LookupsState, getters, rootState, rootGetters) {
    return (type?: string): NumericCodeValue[] => {
      const allOrgans = getters.organOptions(type) || [];
      const filtered = allOrgans.filter((option: NumericCodeValue) => { return !rootGetters['utilities/isExpired'](option.expired_date); });
      return filtered;
    };
  },

  causeOfDeathDonor(state: LookupsState) {
    return state.cause_of_death_donor || [];
  },
  bloodTypesForDonor(state: LookupsState) {
    const bloodLookup = state.blood_type;
    if (!bloodLookup) {
      return [];
    }
    return bloodLookup.filter((bt) => {
      return bt.donor;
    });
  },
  bloodTypesForLivingDonor(state: LookupsState) {
    const bloodLookup = state.blood_type;
    if (!bloodLookup) {
      return [];
    }
    return bloodLookup.filter((bt) => {
      return bt.living_donor;
    });
  },
  rhIndicatorsForDonor(state: LookupsState) {
    const rhIndicatorLookup = state.rh_indicator;
    if (!rhIndicatorLookup) {
      return [];
    }
    return rhIndicatorLookup.filter((rh) => {
      return rh.donor;
    });
  },
  rhIndicatorsForLivingDonor(state: LookupsState) {
    const rhIndicatorLookup = state.rh_indicator;
    if (!rhIndicatorLookup) {
      return [];
    }
    return rhIndicatorLookup.filter((rh) => {
      return rh.living_donor;
    });
  },
  exceptionalDistributionOptionsForDonor(state: LookupsState) {
    const options = state.donor_exceptional_distribution || [];
    return options.filter((option) => {
      return option.donor;
    });
  },
  exceptionalDistributionOptionsForLivingDonor(state: LookupsState) {
    const exdLookup = state.donor_exceptional_distribution;
    if (!exdLookup) {
      return [];
    }
    return exdLookup.filter((option) => {
      return option.living_donor;
    });
  },
  referralDecisionOptions(state: LookupsState) {
    const referralDecisions = state.recipient_referral_decision;
    if (!referralDecisions) {
      return [];
    }
    // Filter options to remove internal only items
    const referralOptions = filterInternalOptions(referralDecisions, ['prerequisite_decision_code', 'internal']);
    // replace lookup with filtered
    state.recipient_referral_decision = referralOptions;
    return referralOptions;
  },
  consultationDecisionOptions(state: LookupsState) {
    const consultationDecisions = state.recipient_consultation_decisions;
    if (!consultationDecisions) {
      return [];
    }
    // Filter options to remove internal only items
    const consultationOptions = filterInternalOptions(consultationDecisions, ['prerequisite_decision_code', 'internal']);
    // replace lookup with filtered
    state.recipient_consultation_decisions = consultationOptions;
    return consultationOptions;
  },
  assessmentDecisionOptions(state: LookupsState) {
    const assessmentDecisions = state.recipient_assessment_decisions;
    if (!assessmentDecisions) {
      return [];
    }
    // Filter options to remove internal only items
    const assessmentOptions = filterInternalOptions(assessmentDecisions, ['prerequisite_decision_code', 'internal']);
    // replace lookup with filtered
    state.recipient_assessment_decisions = assessmentOptions;
    return assessmentOptions;
  },
  offerTypes(state: LookupsState) {
    const offerTypes: OfferType[] = state.offer_types || [];
    if (!offerTypes) {
      return [];
    }
    const filteredOfferTypes = filterInternalOptions(offerTypes, ['internal']);
    return filteredOfferTypes;
  },
  offerResponses(state: LookupsState) {
    const offerResponses = state.offer_responses;
    if (!offerResponses) {
      return [];
    }
    const filteredOfferResponses = filterInternalOptions(offerResponses, ['internal']);
    return filteredOfferResponses;
  },
  offerReasons(state: LookupsState) {
    return state.offer_reasons;
  },
  // Build mapping of locus values between the Serologic and Molecular formats, based on HLA Dictionary lookup
  hlaSerologicLociToMolecularMap(state: LookupsState): { [key: string]: string } {
    // Cache each locus found along with number of molecular codes, to resolve collisions in favour of most likely locus e.g. DRB1
    // This is needed because there is at least one serologic locus (DR) that maps to multiple molecular loci (DRB1, DRB3, DRB4, DRB5)
    // This uses a generalized data-driven approach, such that the HLA Dictionary itself determines the locus mapping behaviour.
    // i.e. Serologic locus to Molecular locus is 1-to-Many
    const locusMappingWithCounts: { [key: string]: { molecularLocus: string, molecularCount: number } } = {};
    const dictionary = state.hla_dictionary || [];
    dictionary.forEach((entry: HlaDictionaryEntry) => {
      const serologicLocus = entry.serological_locus;
      const molecularCount = (entry?.sub_tables?.molecular_codes || []).length;
      const previousMapping = locusMappingWithCounts[serologicLocus] || {};
      const previousCount = previousMapping?.molecularCount || 0;
      if (molecularCount > previousCount) {
        const molecularLocus = entry.code;
        locusMappingWithCounts[serologicLocus] = { molecularLocus, molecularCount };
      }
    });
    // Sanitize results found in the cache
    const locusMapping: { [key: string]: string } = {};
    const mappings = Object.entries(locusMappingWithCounts);
    mappings.forEach((mapping) => {
      const serologicLocus = mapping[0];
      const molecularLocus = mapping[1]?.molecularLocus;
      locusMapping[serologicLocus] = molecularLocus;
    });
    // Standardize CTR-style serologic loci
    Object.assign(locusMapping, {
      'Cw': 'C',
      'DPB': 'DPB1',
      'DQB': 'DQB1',
    });
    return locusMapping;
  },
  // Build mapping of locus values between the Molecular and Serologic formats, based on HLA Dictionary lookup
  hlaMolecularLociToSerologicMap(state: LookupsState): { [key: string]: string } {
    // This mapping direction is simpler, because Molecular locus to Serologic locus is Many-to-1
    const locusMapping: { [key: string]: string } = {};
    const dictionary = state.hla_dictionary || [];
    dictionary.forEach((entry: HlaDictionaryEntry) => {
      const serologicLocus = entry.serological_locus;
      const molecularLocus = entry.code;
      locusMapping[molecularLocus] = serologicLocus;
    });
    // Standardize CTR-style serologic loci
    Object.assign(locusMapping, {
      'Cw': 'C',
      'DPB': 'DP',
      'DQB': 'DQ',
      'DRw': 'DR',
    });
    return locusMapping;
  },
  // Fetch lookup entry for HLA antibody SAB testing kit
  lookupHlaAntibodyTestingKit(state: LookupsState) {
    return (code: string|null): HlaAntibodyTestKit|null => {
      if (!code) return null;

      const kits = state.laboratory_hla_antibody_test_kits || [];
      const match = kits.find((kit: HlaAntibodyTestKit) => {
        return kit.code === code;
      });
      return match || null;
    };
  },
  hlaAlleleGroupOverrides(state: LookupsState): { standard: string; override: string }[] {
    const overrides = state.hla_antibody_allele_group_overrides || [];
    const result: { standard: string; override: string }[] = [];
    overrides.forEach((lookup: GenericCodeValue) => {
      result.push( { standard: lookup.code, override: lookup.value });
    });
    return result;
  },
  findSerologicalByMolecular(state: LookupsState) {
    // Return function that takes standardized molecular value input and returns equivalent serological values or undefined
    return (geneLocus: string, molecular: HlaTypingTag): HlaSerologicalRelation[] | undefined => {
      if (!state.hla_dictionary) {
        console.warn('Error retrieving Serological values: no HLA Dictionary');
        return undefined;
      }
      // Retrieve top-level dictionary lookup entry by molecular gene locus
      const locusEntry: HlaDictionaryEntry = state.hla_dictionary.find((entry: HlaDictionaryEntry) => {
        return entry.code === geneLocus;
      });
      if (!locusEntry) {
        console.warn('Error retrieving Serological values: unknown locus', geneLocus);
        return undefined;
      }
      // Identify molecular antigen string with the appropriate format for quering the HLA Dictionary
      const standardMolecular = molecular.standardAlleleSpecific || molecular.standardText;
      // Retrieve Molecular sub-table entry by molecular antigen value
      const molecularEntry: HlaMolecularCode | undefined = locusEntry.sub_tables.molecular_codes.find((entry: HlaMolecularCode) => {
        return entry.code === standardMolecular;
      });
      if (!molecularEntry) {
        // Antigen not found in Molecular subtable for specified gene locus
        return undefined;
      }
      // Retrieve Serological antigens from the subtable
      const serologicalValues: HlaSerologicalRelation[] = molecularEntry.serological;
      return serologicalValues;
    };
  },
  findSerologicalByMostLikelyAllele(state: LookupsState) {
    // Return function that takes standardized most likely allele molecular value input and returns equivalent serological values or undefined
    return (geneLocus: string, mostLikelyAllele: HlaTypingTag): HlaSerologicalRelation[] | undefined => {
      if (!state.hla_dictionary) {
        console.warn('Error retrieving Serological values: no HLA Dictionary');
        return undefined;
      }
      // Retrieve top-level dictionary lookup entry by molecular gene locus
      const locusEntry: HlaDictionaryEntry = state.hla_dictionary.find((entry: HlaDictionaryEntry) => {
        return entry.code === geneLocus;
      });
      if (!locusEntry) {
        console.warn('Error retrieving Serological values: unknown locus', geneLocus);
        return undefined;
      }
      // Identify most likely allele antigen string with the appropriate format for quering the HLA Dictionary
      const standardMostLikelyAllele = mostLikelyAllele.standardAlleleSpecific || mostLikelyAllele.standardText;
      // Retrieve Molecular sub-table entry by most likely allele molecular antigen value
      const molecularEntry: HlaMolecularCode | undefined = locusEntry.sub_tables.molecular_codes.find((entry: HlaMolecularCode) => {
        return entry.code === standardMostLikelyAllele;
      });
      if (!molecularEntry) {
        // Antigen not found in Molecular subtable for specified gene locus
        return undefined;
      }
      // Retrieve Serological antigens from the subtable
      const serologicalValues: HlaSerologicalRelation[] = molecularEntry.serological;
      return serologicalValues;
    };
  },
  findAllSerologicalByAntibodiesArray(state: LookupsState, getters, rootState, rootGetters) {
    // Return function that takes list of antibody tags as string array and returns all equivalent serological values
    return (antibodies: string[]): string[] => {
      if (!state.hla_dictionary) {
        return [];
      }
      // Iterate through each antibody in the list and cache details about what queries are needed
      const queries: { [key: string]: string[] } = {};
      antibodies.forEach((antibody: string) => {
        // Parse antibody
        const parsed = rootGetters['labs/parseHlaAntibodyTag'](antibody);
        const molecularLocus = parsed.locus || 'UNKNOWN';
        const allele = parsed.allele || 'UNKNOWN';
        const locusQueries = queries[molecularLocus] || [];
        locusQueries.push(allele);
        queries[molecularLocus] = locusQueries;
      });

      // Fetch results from HLA Dictionary for each query
      const results: string[] = [];
      Object.entries(queries).forEach((query) => {
        const molecularLocus: string = query[0] || 'UNKNOWN';
        const serologicLocus = getters.hlaMolecularLociToSerologicMap[molecularLocus] || molecularLocus;
        const alleles: string[] = query[1] || [];
        // Retrieve top-level dictionary lookup entry by molecular gene locus
        const locusEntry: HlaDictionaryEntry = state.hla_dictionary.find((entry: HlaDictionaryEntry) => {
          return entry.code === molecularLocus;
        });
        // Iterate over each allele in the query
        alleles.forEach((allele: string) => {
          // Retrieve Molecular sub-table entry by molecular antigen value
          const molecularEntry: HlaMolecularCode | undefined = locusEntry.sub_tables.molecular_codes.find((entry: HlaMolecularCode) => {
            return entry.code === allele;
          });
          // Retrieve Serological antigens from the subtable
          const serologicalEntries: HlaSerologicalRelation[] = molecularEntry ? molecularEntry.serological || [] : [];
          const serologicalValues: string[] = serologicalEntries.map((entry: HlaSerologicalRelation): string => {
            return `${serologicLocus}${entry.value}`;
          });
          // Append all Serologic values equivalent to the Molecular allele queried to the results
          results.push(...serologicalValues);
        });
      });
      // Filter out duplicates
      const unique = uniqueElements(results);
      return unique;
    };
  },
  findEpitopes(state: LookupsState) {
    // Return function that takes list of standardized typing tags as input and returns whether BW4/6 are present in any
    return (antigens: HlaTypingTag[]): string[] => {
      let result: string[] = [];
      if (antigens.length === 0) {
        return result;
      }
      if (!state.hla_dictionary) {
        // Handle unexpected show stopper by alerting user
        alert('Error retrieving BW4/6 Epitopes: no HLA Dictionary');
        return result;
      }
      // Filter dictionary to include only entries with molecular gene loci found in input
      const loci: string[] = [];
      const antigensByLocus: { [locus: string]: HlaTypingTag[] } = {};
      antigens.forEach((antigen: HlaTypingTag) => {
        const geneLocus = antigen.locus;
        if (geneLocus === null || geneLocus === undefined) {
          return;
        }
        if (!loci.includes(geneLocus)) {
          loci.push(geneLocus);
        }
        const antigensForThisLocus = antigensByLocus[geneLocus] || [];
        antigensForThisLocus.push(antigen);
        antigensByLocus[geneLocus] = antigensForThisLocus;
      });
      const lociEntries: HlaDictionaryEntry[] = state.hla_dictionary.filter((entry: HlaDictionaryEntry) => {
        return loci.includes(entry.code);
      });
      if (lociEntries.length === 0) {
        console.warn('Error retrieving BW4/6: unknown locus in', loci);
      }
      // Retrieve all matching HLA Dictionary sub-table entries
      let molecularEntries: HlaMolecularCode[] = [];
      let serologicalEntries: HlaSerologicalCode[] = [];
      lociEntries.forEach((locusEntry: HlaDictionaryEntry) => {
        // Identify antigens associated with this Molecular gene locus
        const queryAntigens = antigensByLocus[locusEntry.code] || [];
        const queryCodes = queryAntigens.map((antigen: HlaTypingTag): string => {
          return antigen.standardAlleleSpecific || antigen.standardText;
        });
        // Retrieve Molecular entries
        const matchingMolecular = locusEntry.sub_tables.molecular_codes.filter((molecularEntry: HlaMolecularCode) => {
          return queryCodes.includes(molecularEntry.code);
        });
        if (matchingMolecular.length > 0) {
          molecularEntries = molecularEntries.concat(matchingMolecular);
        }
        // Retrieve Serological entries
        const matchingSerological = locusEntry.sub_tables.serological_codes.filter((serologicalEntry: HlaSerologicalCode) => {
          return queryCodes.includes(serologicalEntry.code);
        });
        if (matchingSerological.length > 0) {
          serologicalEntries = serologicalEntries.concat(matchingSerological);
        }
      });
      // Overall epitopes results are each true if they are found in any of the matching entries
      let allEpitopesFound: string[] = [];
      molecularEntries.forEach((molecularEntry: HlaMolecularCode) => {
        const epitopes = molecularEntry.epitopes || [];
        allEpitopesFound = allEpitopesFound.concat(epitopes);
      });
      serologicalEntries.forEach((serologicalEntry: HlaSerologicalCode) => {
        const epitopes = serologicalEntry.epitopes || [];
        allEpitopesFound = allEpitopesFound.concat(epitopes);
      });
      result = uniqueElements(allEpitopesFound);
      return result;
    };
  },

  sexOptions(state: LookupsState): Gender[] {
    const genderLookup = state.gender;
    if (!genderLookup) {
      return [];
    }
    // Filter options to only include Male, Female, and Unknown
    const result = genderLookup.filter((option: Gender) => {
      return SEXES_IN_GENDER_TABLE.includes(option.code);
    });
    return result;
  },

  AttachmentCategoryOptions(state: LookupsState) {
    return (enableDonor: boolean): any => {
      const attachment_category = state.attachment_category || [];
      return attachment_category.filter((code: any) => enableDonor ? code.donor === true : code.recipient === true);
    };
  },
  RecipientNotificationEmails(state: LookupsState) {
    return state.oop_recipient_notification_email || [];
  },
  diagnosisValue(state) {
    return (organCode: number, diagnosisCode: number | null): string | undefined => {
      if (organCode === undefined || diagnosisCode === undefined) {
        return undefined;
      }
      // Find the appropriate Organ Code lookup
      const organLookupEntry = state.organ.find((organ: Organ) => {
        return organ.code == organCode;
      });
      if (!organLookupEntry || !organLookupEntry.sub_tables) {
        return undefined;
      }
      // Determine which sub table to fetch options from
      const subtable = organLookupEntry.sub_tables.disease_code || [];

      // Find the matching Organ Specification value
      const match = subtable.find((item: OrganDiseaseCode) => {
        return item.code == diagnosisCode;
      });
      if (!match) {
        return undefined;
      }
      return match.value;

    };
  },
  lungSecondarySpecOptions(state: LookupsState, getters): GenericCodeValue[] {
    if (!state.organ || state.organ.length === 0) return [];
    const lungOrganLookup = state.organ.find((organ: Organ) => {
      return organ.code === OrganCodeValue.Lung;
    });
    if (!lungOrganLookup || !lungOrganLookup.sub_tables) return [];
    const organSpecifications: OrganSpecification[] = lungOrganLookup.sub_tables.organ_specifications || [];
    const recipientOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
      return !!organSpec.recipient;
    });
    if (recipientOrganSpec.length === 0) return [];
    const filteredOrganSpec = getters.filterExpired(recipientOrganSpec);
    const secondaryOptions = filteredOrganSpec.map((spec: OrganSpecification) => {
      return {
        code: spec.code.toString(),
        value: spec.value
      };
    });
    return secondaryOptions;
  },
  causeOfFailureValue(state: LookupsState) {
    return (organCode: number | string, organFailureCode: number | null): string | OrganSpecification[] | undefined => {
      if (organCode === undefined) {
        return undefined;
      }
      // Find the appropriate Organ Code lookup
      const organLookupEntry = state.organ.find((organ: Organ) => {
        return organ.code == organCode;
      });
      if (!organLookupEntry || !organLookupEntry.sub_tables) {
        return undefined;
      }
      // Determine which sub table to fetch options from
      const subtable = organLookupEntry.sub_tables.cause_of_failure || [];
      if (organFailureCode === undefined) {
        return subtable;
      }
      // Find the matching Organ Specification value
      const match = subtable.find((item: OrganSpecification) => {
        return item.code == organFailureCode;
      });
      if (!match) {
        return undefined;
      }
      return match.value;

    };
  },
  possibleEpitopes(state: LookupsState): HlaEpitope[] {
    return state.hla_dictionary_epitopes;
  },

  /**
   * Returns an array of options for Medical Status
   *
   * Combines the code number with the text value.Fetches the Waitlist Medical Status subtable from the Organ Codes
   * lookup corresponding to the selected Journey
   *
   * @param excludeHold whether or not to exclude medical hold values
   * @param organLookup organ lookup object
   * @param organCode organ code
   * @param showExpired show expired medical options, defaults to false
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for medical status
   */
  medicalStatusLookup(state, getters) {
    return (excludeHold: boolean, organLookup?: Organ[], organCode?: number, showExpired = false): OrganWaitlistMedicalStatus[] => {
      if (!organLookup || !organCode) {
        return [];
      }
      // Retrieve information based on Journey Organ
      const organLookupEntry = organLookup.find((organ: Organ) => {
        return organ.code === organCode;
      });
      if (!organLookupEntry || !organLookupEntry.sub_tables) {
        return [];
      }
      // Determine which sub table to fetch options from
      const medicalStatuses = organLookupEntry.sub_tables.waitlist_medical_status || [];
      // Include code numbers in display value for medical statuses
      const mapped = medicalStatuses.map((status: OrganWaitlistMedicalStatus) => {
        const displayValue = `${status.code} - ${status.value}`;
        const option: OrganWaitlistMedicalStatus = {
          code: status.code,
          rawValue: status.value,
          value: displayValue,
          disabled: status.disabled,
          effective_date: status.effective_date,
          expired_date: status.expired_date,
          priority: status.priority,
          restricted_permissions: status.restricted_permissions,
          urgent_listing_option: status.urgent_listing_option,
        };
        if (excludeHold) {
          // Disable 0 - Temporarily On Hold
          option.disabled = status.code == OrganWaitlistMedicalStatusValue.OnHold;
        }
        return option;
      });
      return showExpired ? mapped : getters.filterExpired(mapped);
    };
  },

  /**
   * Returns an array of options for Secondary Medical Status
   *
   * Combines the code number with the text value. Fetches the Waitlist Medical Status subtable from the Organ Codes
   * lookup corresponding to the selected Journey, and filters out non-secondary medical statuses
   *
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for secondary medical status
   */
  secondaryMedicalStatusLookup(state, getters) {
    return (organLookup?: Organ[], organCode?: number): OrganWaitlistMedicalStatus[] => {
      if (!organLookup || !organCode) {
        return [];
      }
      // Retrieve information based on Journey Organ
      const organLookupEntry = organLookup.find((organ: Organ) => {
        return organ.code === organCode;
      });
      if (!organLookupEntry || !organLookupEntry.sub_tables) {
        return [];
      }
      // Determine which sub table to fetch options from
      const medicalStatuses = organLookupEntry.sub_tables.waitlist_medical_status || [];
      // Exclude medical statuses that do not apply to Secondary Medical Status
      const filteredMedicalStatuses = medicalStatuses.filter((status: OrganWaitlistMedicalStatus) => {
        return !HEART_STATUSES_EXCLUDED_FROM_SECONDARY.includes(status.code);
      });
      // Include code numbers in display value for medical statuses
      const mapped = filteredMedicalStatuses.map((status: OrganWaitlistMedicalStatus) => {
        const displayValue = `${status.code} - ${status.value}`;
        // Disable 0 - Temporarily On Hold
        const disabled = status.code == OrganWaitlistMedicalStatusValue.OnHold;
        return {
          code: status.code,
          value: displayValue,
          disabled,
          effective_date: status.effective_date,
          expired_date: status.expired_date
        };
      });
      return getters.filterExpired(mapped);
    };
  },

  /**
   * Returns a text representation of a Medical Status, including Secondary Medical Status for heart journeys
   *
   * @param organLookup lookup table for organ codes
   * @param organCode numeric organ code
   * @param medicalStatusCode string code for organ-specific waitlist medical status
   * @param secondaryMedicalStatusCode string code for organ-specific waitlist medical status
   * @param showExpired show expired medical options, defaults to false, passed to medicalStatusLookup
   * @returns {string|undefined} text representation of Medical Statuses, or undefined if there is not enough data
   */
  describeMedicalStatus(state, getters) {
    return (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null, showExpired = false): string|undefined => {
      let medicalStatusDetails: string|undefined = undefined;
      if (medicalStatusCode !== undefined || secondaryMedicalStatusCode !== undefined) {
        const medicalStatusDisplayOption = getters.medicalStatusLookup(false, organLookup, organCode, showExpired).find((status: OrganWaitlistMedicalStatus) => {
          return status.code == medicalStatusCode;
        });
        const medicalStatusValue = medicalStatusDisplayOption ? medicalStatusDisplayOption.value : undefined;
        const secondaryMedicalStatusDisplayOption = getters.secondaryMedicalStatusLookup(organLookup, organCode).find((status: OrganWaitlistMedicalStatus) => {
          return status.code == secondaryMedicalStatusCode;
        });
        const secondaryMedicalStatusValue = secondaryMedicalStatusDisplayOption ? secondaryMedicalStatusDisplayOption.value : undefined;
        medicalStatusDetails = secondaryMedicalStatusValue ? `${medicalStatusValue} (${secondaryMedicalStatusValue})` : medicalStatusValue;
      }
      return medicalStatusDetails;
    };
  },
  medicalStatusPriority(state, getters) {
    return (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null): number => {
      let priority = -1;
      if (medicalStatusCode != undefined) {
        const lookup = getters.medicalStatusLookup(false, organLookup, organCode).find((status: OrganWaitlistMedicalStatus) => {
          return status.code == medicalStatusCode;
        });
        priority = lookup ? lookup.priority : -1;
      }
      return priority;
    };
  },
  hlaTestingKits(state): HlaAntibodyTestKit[] {
    return state.laboratory_hla_antibody_test_kits;
  },

  // Filter Testing Kits options to Class I Testing Kits
  hlaTestingKitClass1Options(state, getters): GenericCodeValue[] {
    const sabKits = state.laboratory_hla_antibody_test_kits || [];
    const kitsByClass = sabKits.filter((kit: HlaAntibodyTestKit) => {
      return kit.class === 1;
    });
    return kitsByClass;
  },

  // Filter Testing Kits options to Class II Testing Kits
  hlaTestingKitClass2Options(state, getters): GenericCodeValue[] {
    const sabKits = state.laboratory_hla_antibody_test_kits || [];
    const kitsByClass = sabKits.filter((kit: HlaAntibodyTestKit) => {
      return kit.class === 2;
    });
    return kitsByClass;
  },
  sample_types(state) {
    return state.sample_types;
  },
  offerResponseCode(state, getters) {
    return (code: string|null): string|undefined => {
      if (!code) { return undefined; }
      const offer = getters.offerResponses.find((item: any) => {
        return item.code === code;
      });
      if (!offer) return undefined;
      return offer.value;
    };
  },
  /**
   * Return no offer reason category
   *
   * @param code reason category
   * @returns {string} No Offer Reason category
   */
  noOfferReasonCategory(state, getters) {
    return (code: number): string|undefined => {
      const noOfferOptions = getters.offerTypes.find((item: any) => {
        return item.code == AllocationOfferTypeValues.NoOffer;
      });
      if (noOfferOptions && noOfferOptions.sub_tables) {
        const reasonCategories: OfferReasonCategory[] = noOfferOptions.sub_tables.no_offer_reason_categories;
        const reasonCategory = reasonCategories.find((item: OfferReasonCategory) => item.code == code);
        return reasonCategory ? reasonCategory.value : undefined;
      } else {
        return undefined;
      }
    };
  },
  /**
   * Return no offer reason code
   *
   * @param code reason code
   * @returns {string} No Offer Reason code
   */
  noOfferReasonCode(state, getters) {
    return (category: string, code: string): string|undefined => {
      const noOfferOptions = getters.offerTypes.find((item: any) => {
        return item.code == AllocationOfferTypeValues.NoOffer;
      });
      if (noOfferOptions && noOfferOptions.sub_tables) {
        const reasonCategories = noOfferOptions.sub_tables.no_offer_reason_categories;
        const reasonCategory = reasonCategories.find((item: GenericCodeValue) => item.code == category);
        const reasons = reasonCategory.sub_tables.no_offer_reasons;
        const reasonCode = reasons.find((item: GenericCodeValue) => item.code == code);
        return reasonCode ? reasonCode.value : undefined;
      } else {
        return undefined;
      }
    };
  },
  transplantTypeLookup(state: LookupsState) {
    return (organCode: number | string): string | OrganSpecification[] | undefined => {
      if (organCode === undefined) {
        return undefined;
      }
      // Find the appropriate Organ Code lookup
      const organLookupEntry = state.organ.find((organ: Organ) => {
        return organ.code == organCode;
      });
      if (!organLookupEntry || !organLookupEntry.sub_tables) {
        return [];
      }
       // Fetch appropriate options sub table
      const organSpecifications = organLookupEntry?.sub_tables?.organ_specifications || [];
      const transplantOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
        return !!organSpec.transplant;
      });
      return transplantOrganSpec;
    };
  },
  /**
   * Return date range for minimum Date of Birth
   *
   * @returns {string} minimum date of birth range (minus 90 years)
   */
  getDobMinDateLimit(state, getters) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 90);
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  },
  /**
   * Return date range for maximum Date of Birth
   *
   * @returns {string} maximum date of birth range (current year)
   */
  getDobMaxDateLimit(state, getters) {
    const date = new Date();
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  },
  exdAcceptanceReasons(state): any[] | undefined {
    const reasons = state.exceptional_distribution_acceptance_reasons;
    if (!reasons) {
      return [];
    }
    return reasons;
  },
  bloodSubTypes(state) {
    return (bloodType: string): SubBloodType[] => {
      // Return if no bloodType
      if (bloodType == null) {
        return [];
      }
      // Copy sub blood types
      let filteredSubBloodTypes = state.sub_blood_type || [];
      // A type filter
      if (bloodType == BloodTypeValue.A) {
        filteredSubBloodTypes = filteredSubBloodTypes.filter((item: any) => {
          return item.code == SubBloodTypeValue.A1 || item.code == SubBloodTypeValue.A2;
        });
      }
      // AB type filters
      if (bloodType == BloodTypeValue.AB) {
        filteredSubBloodTypes = filteredSubBloodTypes.filter((item: any) => {
          return item.code == SubBloodTypeValue.A1B || item.code == SubBloodTypeValue.A2B;
        });
      }
      return filteredSubBloodTypes;
    };
  },
  causeOfDeathTypeLookup(state: LookupsState) {
    return (causeOfDeathCategoryCode: string) => {
      if (causeOfDeathCategoryCode === undefined) {
        return undefined;
      }
      // Find the lookup entry
      const causeOfDeathCategoryLookup = state.cause_of_death_category.find((causeOfDeath: GenericCodeValue) => {
        return causeOfDeath.code == causeOfDeathCategoryCode;
      });
      if (!causeOfDeathCategoryLookup || !causeOfDeathCategoryLookup.sub_tables) {
        return [];
      }
      // Feth the appropriate sub table
      return causeOfDeathCategoryLookup.sub_tables.cause_of_death_category_types || [];
    };
  },

  // Deceased Donor Serology uses all the donor virology result options
  deceasedDonorVirologyResultOptions(state: LookupsState): GenericCodeValue[] {
    const options: GenericCodeValue[] = state.donor_virology_result_codes || [];
    const sorted = sortByPrecedence(options, VIROLOGY_RESULT_CODE_PRECEDENCE);
    return sorted;
  },

  // Living Donor Virology uses all options except for Pending / In Progress
  livingDonorVirologyResultOptions(state: LookupsState): GenericCodeValue[] {
    const options: GenericCodeValue[] = state.donor_virology_result_codes || [];
    const filtered = options.filter((option: GenericCodeValue) => {
      return option.code !== VirologyResultCodesValue.Pending;
    });
    const sorted = sortByPrecedence(filtered, VIROLOGY_RESULT_CODE_PRECEDENCE);
    return sorted;
  },

  // Filter out internal journey cancellation reasons
  reasonForRemovalOptions(state: LookupsState): NumericCodeValue[] {
    // Check data dependencies
    if (!state.waitlist_removal_reason_codes) {
      return [];
    }

    return filterInternalOptions(state.waitlist_removal_reason_codes, ['waitlist_removal_reason_codes', 'internal']);
  },
  /**
   * Get organ specificate code value
   *
   * Note: works for all three types of specifications: recipient, offer, and transplant
   *
   * @param organ organ code
   * @param organSpecificationCode organ specification code
   * @returns {string} organ specification code value
   */
   getOrganSpecificationName(state: LookupsState, getters) {
    return (organCode?: number|null, organSpecificationCode?: number|null) => {
      if (!organCode) return null;

      const organLookup = state.organ || null;
      if (!organLookup) return null;

      // Retrieve information based on organCode
      const organLookupEntry = organLookup.find((organ: Organ) => {
        return organ.code.toString() === organCode.toString();
      });

      // Return organ name on its own if there is no secondary specification
      if (!organSpecificationCode) return organLookupEntry.value;

      // Return if nothing is in our sub_table
      if (!organLookupEntry || !organLookupEntry.sub_tables) return null;

      // The unfiltered list of options
      const organSpecifications = organLookupEntry.sub_tables.organ_specifications || [];

      const filteredSpecification = organSpecifications.find((specification: any) => {
        return specification.code == organSpecificationCode;
      });

      return filteredSpecification ? filteredSpecification.value : null;
    };
  },
  /**
   * Get notification channels
   *
   * @returns {string[]} notification channels
   */
   getNotificationChannels(state: any, getters) {
    return state.notification_channels ? state.notification_channels : null;
  },
  /**
   * Get notification channel codes by roles
   *
   * @param roles array of roles
   * @returns {string[]} notification channel codes
   */
   getNotificationChannelCodesByRoles(state: LookupsState, getters) {
    return (roles: string[]): string[] => {
      const availableChannels = getters.getNotificationChannels;

      if (!availableChannels) return [];

      // find channels available to user
      let newChannels: string[] = [];
      roles.map((role: string) => {
        const result = availableChannels[role];
        if (result) { newChannels = [ ...newChannels, ...result]; }
      });

      // return unique list
      return [...new Set(newChannels)];
    };
  },
  /**
   * Get notification channels by roles returning expanded data from notifiable_event_channels lookup
   *
   * @param roles array of roles
   * @returns {NotifiableEventChannel[]} notification channel lookup items
   */
  findNotificationChannelsFromLookupByRoles(state, getters, rootState, rootGetters) {
    return (roles: string[]): NotifiableEventChannel[] => {
      // get channel codes connected to roles
      const channels = getters.getNotificationChannelCodesByRoles(roles).filter((x: any) => !!x);
      // get index of notification event channels
      const notifiable_event_channels = state.notifiable_event_channels || [];
      // build object containing notifiable_event_channels matched to ones available in roles
      const result = channels.map((channel: string) => {
        const found = notifiable_event_channels.find((item: any) => item.code == channel);
        if (found) {
          // we don't get optional methods so build them
          const available_delivery_mechanisms = found.available_delivery_mechanisms || [];
          const required_delivery_mechanisms = found.required_delivery_mechanisms || [];
          const optional_delivery_mechanisms: string[] = [];
          available_delivery_mechanisms.map((item: string) => {
            if (!required_delivery_mechanisms.includes(item)) { optional_delivery_mechanisms.push(item); }
          });
          found.optional_delivery_mechanisms = optional_delivery_mechanisms;

          // attach an order value to the item
          // find order by key, otherwise default to null
          const ordering = NotificationCodeOrdering as any;
          found.order = ordering[channel] ? ordering[channel] : null;

          return found;
        }
      });

      // sort results by order attribute
      const result_sorted = result.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);

      return result_sorted;
    };
  },
  // /**
  //  * Get notification channels by roles returning expanded data from notifiable_event_channels lookup
  //  *
  //  * @param roles array of roles
  //  * @returns {NotifiableEventChannel[]} notification channel lookup items
  //  */
  // getMedicalStatusOptions(state, getters) {    
  //   const filter: any[] = [];
  //   const organOptions = getters.organOptions();
  //   organOptions.map((organ: { code: number; value: string }) => {
  //     const options: OrganWaitlistMedicalStatus[] = getters.medicalStatusLookup(true, this.organLookup, organ.code);
  //     // Map lookup format (code/value) to filter option format (value/text)
  //     options.forEach((option: OrganWaitlistMedicalStatus) => {
  //       const values: any[] = filter.flat().map((f) => { return f.value; });

  //       if(values.indexOf(option.value) < 0 ){
  //         // return {
  //         // };
  //         filter.push({
  //           medicalName: option.value,
  //           medicalCode: option.code,
  //           organName: getters.organName(organ.code), 
  //           organCode: organ.code,
  //         });
  //       }
  //     });
  //   });
  //   return filter;
  // }

  decisionTypes(state, getters) {
    const categories = state.decision_categories;
    if (!categories || categories.length < 0) return [];

    const decision_types = categories[0].sub_tables && categories[0].sub_tables.decision_types ? categories[0].sub_tables.decision_types : [];
    return decision_types;
  },

  decisionResponseTypes(state, getters) {
    return state.decision_response_types || [];
  },

  decisionFinalDecisionTypes(state, getters) {
    return state.decision_final_decision_types || [];
  },

  decisionMeldDerFrequency(state, getters) {
    return state.decision_meld_der_frequency || [];
  },

  getInsurancePlanMaskFormat(state, getters) {
    return (code: number|null): string => {
      if (!code) return '';
      const formats = state.insurance_plan_codes || [];
      const found = formats.find((item: InsurancePlanCode) => {
        return item.code == code;
      });
      const mask = found && found.insurance_number_mask_format ? found.insurance_number_mask_format : '';
      return mask;
    };
  },

  /**
   * If province is ontario format the insurance number as #### ### ###
   *
   * @returns {boolean} string for masked insurance number
   */
  getMaskFormatType(state, getters) {
    return (insurance_type: number): string => {
      const maskFormat = getters.getInsurancePlanMaskFormat(insurance_type);
      return maskFormat;
    };
  }
};
